import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const deliveryInstructionsSlice = createSlice({
  name: 'deliveryinstructions',
  initialState: [],
  reducers: {
    addInstructions: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push(action.payload);
    },
    clearInstructions: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const deliveryInstructionsReducer = deliveryInstructionsSlice.reducer;

export const {
  addInstructions,
  clearInstructions
} = deliveryInstructionsSlice.actions;