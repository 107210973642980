import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an orderbook, as well as the seleced shipping order and the selected billing order

const orderSlice = createSlice({
  name: 'order',
  initialState: [],
  reducers: {
    addOrder: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload});
    },
    clearOrder: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const orderReducer = orderSlice.reducer;

export const {
  addOrder,
  clearOrder
} = orderSlice.actions;