import { createSlice } from '@reduxjs/toolkit';

const gridviewSlice = createSlice({
  name: 'grid_view',
  initialState: {
    grid_view: true
  },
  reducers: {
    setGridView: (state, action) => {
      state.grid_view = true;
    },
    setListView: (state, action) => {
      state.grid_view = false;
    }
  },
});

export const gridviewReducer = gridviewSlice.reducer;

export const {
  setGridView,
  setListView,
} = gridviewSlice.actions;