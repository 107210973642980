import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const customDeliveryNumberSlice = createSlice({
  name: 'customDeliveryNumber',
  initialState: [],
  reducers: {
    addCustomDeliveryNumber: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push(action.payload);
    },
    clearCustomDeliveryNumber: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const customDeliveryNumberReducer = customDeliveryNumberSlice.reducer;

export const {
  addCustomDeliveryNumber,
  clearCustomDeliveryNumber
} = customDeliveryNumberSlice.actions;