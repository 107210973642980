import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const filtersSlice = createSlice({
  name: 'filters',
  initialState: [],
  reducers: {
    addFilters: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload});
    },
    clearFilters: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const filtersReducer = filtersSlice.reducer;

export const {
  addFilters,
  clearFilters
} = filtersSlice.actions;