import { createSlice } from '@reduxjs/toolkit';

const latestbloghideSlice = createSlice({
  name: 'latestbloghide',
  initialState: [],
  reducers: {
    setLatestbloghide: (state, action) => {
      // remove the current entry if any
      //state.splice(0, state.length)
      state.push(action.payload);
    },
  },
});

export const latestbloghideReducer = latestbloghideSlice.reducer;

export const {
  setLatestbloghide,
} = latestbloghideSlice.actions;