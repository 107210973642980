// log the pageview with their URL
export const pageview = (url) => {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
    })
}

// log specific events happening.
export const event = ({ action, params }) => {
    window.gtag('event', action, params)
}


export const view_item = (product) => {
    window.gtag('event', 'view_item', {
        items: [
            {
                item_id: product.sku,
                item_name: product.name,
            }
        ]
    });

    fbq('track', 'ViewContent', {
        content_ids: [product.sku], 
        content_type: 'product',
    });
}

export const add_to_cart = (variation) => {
    window.gtag('event', 'add_to_cart', {
        items: [
            {
                item_id: variation.sku,
                item_name: variation.name,
                price: variation.price_inc_vat / 100,
                quantity: variation.quantity_to_add
            }
        ]
    });

    fbq('track', 'AddToCart', {
        content_ids: [variation.sku], 
        content_type: 'product', 
    });
}

export const remove_from_cart = (variation) => {
    window.gtag('event', 'remove_from_cart', {
        items: [
            {
                item_id: variation.sku,
                item_name: variation.name,
                price: variation.price_inc_vat / 100,
                quantity: variation.quantity_to_add
            }
        ]
    });
}

export const add_to_wishlist = (id) => {
    window.gtag('event', 'add_to_wishlist', {
        items: [
            {
                item_id: id,
            }
        ]
    });
}

export const view_cart = (cart) => {
    window.gtag('event', 'view_cart', {
        items: cart.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
}

export const begin_checkout = (cart) => {
    window.gtag('event', 'begin_checkout', {
        items: cart.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
}

export const add_payment_info = (cart, payment_method) => {
    window.gtag('event', 'add_payment_info', {
        payment_type: payment_method,
        items: cart.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
}

export const add_shipping_info = (cart, shipping) => {
    window.gtag('event', 'add_shipping_info', {
        shipping_tier: shipping,
        items: cart.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
}


export const purchase = (order) => {
    window.gtag('event', 'purchase', {
        transaction_id: (order.CustomerDocumentNo) ? (order.CustomerDocumentNo) : ("WEB" + order.id.toString().padStart(10, '0')),
        value: order.order_total_inc_vat / 100,
        currency: 'GBP',
        tax: (order.order_total_inc_vat - order.order_total_ex_vat) / 100,
        shipping: order.shipping_total_inc_vat / 100,
        coupon: order.discount_code,
        items: order.items.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
    
    let itemsArray = []
    order.items.map((item) => {
        itemsArray.push(item.sku)
    })

    fbq('track', 'Purchase', {
        content_ids: itemsArray, 
        value: order.order_total_inc_vat / 100, 
        currency: 'GBP', 
        content_type: 'product', 
    });
}

export const purchaserole = (order, user) => {
    var role = "guest_purchase";
    // check if merchant role is there
    if (user && user[0] && user[0].roles != undefined) {
        role = "consumer_purchase";
        if(user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
            role = "merchant_purchase";
        }
    }
    window.gtag('event', role, {
        transaction_id: (order.CustomerDocumentNo) ? (order.CustomerDocumentNo) : ("WEB" + order.id.toString().padStart(10, '0')),
        value: order.order_total_inc_vat / 100,
        currency: 'GBP',
        tax: (order.order_total_inc_vat - order.order_total_ex_vat) / 100,
        shipping: order.shipping_total_inc_vat / 100,
        coupon: order.discount_code,
        items: order.items.map((item) => {
            return {
                item_id: item.sku,
                item_name: item.name,
                price: item.price_inc_vat / 100,
                quantity: item.quantity
            }
        })
    });
}

export const search = (search) => {
    window.gtag("event", "search", {
        search_term: search
    });
}

export const login_info = (user) => {
    var role = "";
    // check if merchant role is there
    if (user.roles != undefined) {
        role = "user";
        if(user.roles.findIndex((element) => element.name == "merchant") >= 0) {
            role = "merchant";
        }
    }
    window.gtag('event', 'login_info', {
        user_id: user.id,
        user_name: user.name,
        user_role: role
    });
}

  


