import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const addressSlice = createSlice({
  name: 'address',
  initialState: [],
  reducers: {
    addAddresses: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload});
    },
    clearAddresses: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const addressReducer = addressSlice.reducer;

export const {
  addAddresses,
  clearAddresses
} = addressSlice.actions;