import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const shippingSlice = createSlice({
  name: 'shipping',
  initialState: [],
  reducers: {
    addShipping: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload});
    },
    clearShipping: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const shippingReducer = shippingSlice.reducer;

export const {
  addShipping,
  clearShipping
} = shippingSlice.actions;