import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const tabsSlice = createSlice({
  name: 'tabs',
  initialState: [],
  reducers: {
    addTab: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push(action.payload);
    },
    clearTab: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const tabsReducer = tabsSlice.reducer;

export const {
  addTab,
  clearTab
} = tabsSlice.actions;