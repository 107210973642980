import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: [],
  reducers: {
    addUser: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload });
    },
    addUserAddress: (state, action) => {
      // see if we already have an entry in our user store
      var itemExists = false
      if (action.payload && action.payload.id) {
        itemExists = state[0].addresses.find((item) => item.id === action.payload.id);
      }
      // we have to check it exists before getting the index, as the index can be '0th'
      if (itemExists) {
        const index = state[0].addresses.findIndex((item) => item.id === action.payload.id);
        // if we do, remove it and add a new one
        state[0].addresses.splice(index, 1);
        state[0].addresses.push({ ...action.payload });
      } else {
        // if we dont, add it
        state[0].addresses.push({ ...action.payload });
      }
    },
    removeUserAddress: (state, action) => {
      // see if we already have an entry in our user store
      var itemExists = false

      if (action.payload && action.payload.id) {
        itemExists = state[0].addresses.find((item) => item.id === action.payload.id);
      }
      // we have to check it exists before getting the index, as the index can be '0th'
      if (itemExists) {
        const index = state[0].addresses.findIndex((item) => item.id === action.payload.id);
        // if we do, remove it
        state[0].addresses.splice(index, 1);
      } else {
        // if we dont, dont do anything
      }
    }, 
    removeUser: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
    updateToken: (state, action) => {
      // see if we already have an entry in our user store
      state[0].token = action.payload.token
    }
  },
});

export const userReducer = userSlice.reducer;

export const {
  addUser,
  removeUser,
  addUserAddress,
  removeUserAddress,
  updateToken
} = userSlice.actions;