import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const paymentmethodSlice = createSlice({
  name: 'paymentmethod',
  initialState: [],
  reducers: {
    addPaymentMethod: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
      state.push({ ...action.payload});
    },
    clearPaymentMethod: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const paymentmethodReducer = paymentmethodSlice.reducer;

export const {
  addPaymentMethod,
  clearPaymentMethod
} = paymentmethodSlice.actions;