import { createSlice } from '@reduxjs/toolkit';

const minicartSlice = createSlice({
  name: 'minicart',
  initialState: {
    minicart: false
  },
  reducers: {
    toggleCart: (state, action) => {
      if ( state.minicart == true) {
        state.minicart = false
      } else {
        state.minicart = true;
      }
    },
    closeCart: (state, action) => {
      state.minicart = false
    },
  },
});

export const minicartReducer = minicartSlice.reducer;

export const {
  toggleCart,
  closeCart
} = minicartSlice.actions;