import {configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

import { cartReducer } from './cart.slice';
import { minicartReducer } from './minicart.slice';
import { addressReducer } from './address.slice';
import { userReducer } from './user.slice';
import { shippingReducer } from './shipping.slice';
import { paymentmethodReducer } from './paymentmethod.slice';
import { gridviewReducer } from './gridview.slice';
import { orderReducer } from './order.slice';
import { filtersReducer } from './filters.slice';
import { returnsReducer } from './returns.slice';
import { voucherReducer } from './voucher.slice';
import { deliveryInstructionsReducer } from './deliveryinstructions.slice'
import { customDeliveryNumberReducer } from './customDeliveryNumber.slice'
import { latestbloghideReducer} from './latestbloghide.slice'
import { mobileviewReducer} from './mobileview.slice'


import { tabsReducer } from './tab.slice';

const reducers = combineReducers({
  cart: cartReducer,
  minicart: minicartReducer,
  address: addressReducer,
  user: userReducer,
  shipping: shippingReducer,
  paymentmethod: paymentmethodReducer,
  grid_view: gridviewReducer,
  order: orderReducer,
  filters: filtersReducer,
  returns: returnsReducer,
  voucher: voucherReducer,
  delivery_instructions: deliveryInstructionsReducer,
  customDeliveryNumber: customDeliveryNumberReducer,
  latestbloghide: latestbloghideReducer,
  tabs: tabsReducer,
  mobileview: mobileviewReducer,
});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;

