import { createSlice } from '@reduxjs/toolkit';

const mobileviewSlice = createSlice({
  name: 'mobileview',
  initialState: {
    mobileview: true
  },
  reducers: {
    enableMobileView: (state) => {
      state.mobileview = true;
    },
    disableMobileView: (state) => {
      state.mobileview = false;
    },
  },
});

export const mobileviewReducer = mobileviewSlice.reducer;

export const {
  enableMobileView,
  disableMobileView
} = mobileviewSlice.actions;