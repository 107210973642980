import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const returnsSlice = createSlice({
  name: 'returns',
  initialState: [],
  reducers: {
    toggleReturn: (state, action) => {
      const index = state.findIndex((item) => item.id == action.payload.id);
      if (index >= 0) {
        state.splice(index, 1);
      } else {
        state.push({ ...action.payload, reason: "The item is no longer required." });
      }
    },
    addReason: (state, action) => {
      const index = state.find((item) => item.id == action.payload.id);
      if (index) {
        index.reason = action.payload.value
      }
    },
    addReturn: (state, action) => {
      // find if it already xists
      state.push({ ...action.payload });
    },
    removeReturn: (state, action) => {
      const index = state.findIndex((item) => item.id == action.payload.id);
      state.splice(index, 1);
    },
    clearReturns: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const returnsReducer = returnsSlice.reducer;

export const {
  addReturns,
  removeReturn,
  toggleReturn,
  clearReturns,
  addReason
} = returnsSlice.actions;