import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    addToCart: (state, action) => {
      const itemExists = state.find((item) => item.id === action.payload.id);
      if (itemExists) {
        // check we have stock, if not, dont go above available stock 
        if (action.payload.backorder == 1) {
          itemExists.quantity = itemExists.quantity + action.payload.add_quantity;
        } else {
          if (itemExists.quantity >= action.payload.quantity) {
            itemExists.quantity = action.payload.quantity
          } else {
            itemExists.quantity = itemExists.quantity + action.payload.add_quantity;
          }
        }
      } else {
        state.push({ ...action.payload, quantity: action.payload.add_quantity });
      }
    },
    changeInCart: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index >= 0) {
        // replace that with the passed stuff
        let newstate = action.payload
        // replace the state with the new item
        state.splice(index, 1, newstate)
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.find((item) => item.id === action.payload);
      item.quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.find((item) => item.id === action.payload);
      if (item.quantity === 1) {
        const index = state.findIndex((item) => item.id === action.payload);
        state.splice(index, 1);
      } else {
        item.quantity--;
      }
    },
    removeFromCart: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload);
      state.splice(index, 1);
    },
    clearCart: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const cartReducer = cartSlice.reducer;

export const {
  addToCart,
  changeInCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  clearCart
} = cartSlice.actions;