import { createSlice } from '@reduxjs/toolkit';

// this one needs to handle an addressbook, as well as the seleced shipping address and the selected billing address

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: [],
  reducers: {
    addVoucher: (state, action) => {
      // find if it already xists
      state.splice(0, state.length)
      // add the new one - only one code at a time
      state.push(action.payload);
    },
    clearVoucher: (state, action) => {
      // remove the current entry if any
      state.splice(0, state.length)
    },
  },
});

export const voucherReducer = voucherSlice.reducer;

export const {
  addVoucher,
  clearVoucher,
} = voucherSlice.actions;